<template>
  <div style="width: 100%; height: 100%">
    <home1 v-if="showHome1 == 1" @toggleComponent="flag => (showHome1 = flag)"></home1>
    <home2 v-else @toggleComponent="flag => (showHome1 = flag)"></home2>
  </div>
</template>
<script>
import home1 from './components/homeTemplate/home1.vue';
import home2 from './components/homeTemplate/home2.vue';

export default {
  components: {
    home1,
    home2,
  },
  data() {
    return {
      showHome1: 1,
    };
  },
};
</script>
