<template>
  <div>
    <div style="padding: 15px; box-sizing: border-box" @click="back">
      <span>{{ $t('index.back') }}</span>
    </div>
    <div style="margin-top: 0px; text-align: center">
      <h3 class="product-header__title app-header__title">
        <font style="vertical-align: inherit; overflow-wrap: break-word">
          <font style="vertical-align: inherit">{{ appInfo.ipa_name }}</font>
        </font>
      </h3>
    </div>
    <div style="text-align: center; font-size: 13px">
      <font style="vertical-align: inherit">
        <font style="vertical-align: inherit; color: #68c6b7">{{ $t('index.dowloadTips') }}</font>
      </font>
    </div>
    <div style="margin: 10px auto; max-width: 70%; display: flex; justify-content: center">
      <img src="@/assets/step.png" alt="" srcset="" style="max-width: 100%; height: auto" />
    </div>
    <div style="margin: 10px auto; max-width: 88%; display: flex; justify-content: center">
      <img src="@/assets/hongbao.png" alt="" srcset="" style="max-width: 100%; height: auto" />
    </div>
    <div style="margin: 10px auto; max-width: 90%; display: flex; justify-content: center; position: relative" @click="jumpUrl(hongbaoAd.url)" v-if="hongbaoAd.status === 'show' && hongbaoAdShow && whiteList.indexOf(appInfo.user_id) === -1">
      <img :src="hongbaoAd.image.includes('https://') ? hongbaoAd.image : hongbaoAd.url + hongbaoAd.image" alt="" srcset="" style="max-width: 100%; height: auto" />
      <div class="topright" @click.stop="hongbaoAdShow = false">x</div>
    </div>
    <div style="margin: 10px auto; max-width: 88%; display: flex; justify-content: center" @click="Receive">
      <img src="@/assets/btn.png" alt="" srcset="" style="max-width: 100%; height: auto" />
    </div>
    <installQuestions :appName="appInfo.ipa_name" :mimetype="appInfo.mimetype" />
  </div>
</template>

<script>
import installQuestions from '../installQuestions.vue';
export default {
  components: { installQuestions },
  name: 'hongbao',
  props: {
    appInfo: {
      type: Object,
    },
    hongbaoAd: {
      type: Object,
    },
    jumpObject: {
      type: Object,
    },
    whiteList: {
      type: String,
    },
  },
  data() {
    return {
      pcFlag: false,
      language: 'zh',
      hongbaoAdShow: true,
    };
  },
  methods: {
    jumpUrl(url) {
      if (url && url.includes('http')) {
        window.open(url);
      }
    },
    Receive() {
      if (this.jumpObject.value && this.jumpObject.value.includes('http')) {
        window.open(this.jumpObject.value);
      }
    },
    back() {
      this.$emit('back');
    },
    is_weixin() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    loadHtml() {
      var div = document.createElement('div');
      div.id = 'weixin-tip';
      div.innerHTML = '<p><img src="./point.png" style="width: 100%;" /></p>'; //遮罩图片
      document.body.appendChild(div);
    },
    loadStyleText(cssText) {
      var style = document.createElement('style');
      style.rel = 'stylesheet';
      style.type = 'text/css';
      try {
        style.appendChild(document.createTextNode(cssText));
      } catch (e) {
        style.styleSheet.cssText = cssText; //ie9以下
      }
      var head = document.getElementsByTagName('head')[0]; //head标签之间加上style样式
      head.appendChild(style);
    },
  },
};
</script>

<style scoped>
.step {
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #3882f2;
  border-radius: 50%;
}
</style>
