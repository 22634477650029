<template>
  <div :class="{ pcBg: pcFlag }">
    <div class="home" v-show="ishome">
      <div v-if="msg" id="msg" v-cloak>
        <template v-if="$route.params.id">
          {{ msg }}
        </template>
        <div v-else class="container" style="margin: 0 20px">
          <h1 style="font-size: 24px; margin-bottom: 16px">
            {{ $t('index.companyName') }}
          </h1>
          <ul style="font-size: 16px">
            <li>
              {{ $t('index.briefIntroduction') }}
            </li>

            <li style="margin-top: 10px">
              <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">{{ $t('index.beian') }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-else-if="appInfo.status === 'hidden' || appInfo.switch == 1" style="width: 80%; text-align: center; background-color: blue; word-wrap: break-word; word-break: break-all; min-height: 200rpx; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
        {{ appInfo.download_error ? appInfo.download_error : $t('index.yixiajia') }}
      </div>
      <div v-else-if="appInfo.uploadpwd && pwdTxt !== appInfo.uploadpwd" id="password" v-cloak>
        <div style="text-align: left">
          {{ $t('index.mima') }}：
          <input type="text" v-model="pwd" :placeholder="$t('index.mimaPlaceholder')" />
        </div>
        <div style="margin-top: 10px; text-align: left; padding-left: 41px; color: red">
          {{ pwdMsg ? $t('index.mimac') : '' }}
        </div>
        <button type="button" @click="tijiao" style="border: 1px solid #ccc; padding: 5px 10px; border-radius: 5px; margin-top: 10px; text-align: right">
          {{ $t('index.tijiao') }}
        </button>
      </div>

      <div class="ember-view">
        <main class="selfclear is-apps-theme" v-cloak v-if="!msg && appInfo.status !== 'hidden' && appInfo.switch == 0 && pwdTxt === appInfo.uploadpwd">
          <input id="localnav-menustate" class="localnav-menustate" type="checkbox" />

          <div class="animation-wrapper is-visible" v-if="!pcFlag">
            <section class="l-content-width section section--hero product-hero">
              <div class="l-row" style="display: flex; align-items: center">
                <div class="product-hero__media l-column small-5 medium-4 large-3 small-valign-top">
                  <picture data-test-we-artwork="" class="we-artwork we-artwork--downloaded product-hero__artwork we-artwork--fullwidth we-artwork--ios-app-icon" style="--background-color: #ffe577" id="ember3">
                    <img class="ember3" :src="appInfo && appInfo.image" decoding="async" alt="" style="border-radius: 22.5%" :class="[pcFlag ? 'w230' : 'we-artwork__image']" />
                  </picture>
                </div>

                <div class="l-column small-7 medium-8 large-9">
                  <header class="product-header app-header product-header--padded-start">
                    <h1 class="product-header__title app-header__title">
                      <font style="vertical-align: inherit; overflow-wrap: break-word">
                        <font style="vertical-align: inherit">{{ appInfo.ipa_name }}</font>
                      </font>
                    </h1>
                    <ul class="product-header__list app-header__list"></ul>
                    <!-- androidFlag -->
                    <div class="installBox" style="position: static; width: 130px" v-if="cannotInstall">
                      <div class="down" style="border-radius: 10px; height: 40px; line-height: 40px; background: #8c8b90">{{ $t('index.no_support') }}{{ androidFlag ? $t('index.android') : ' IOS' }}</div>
                    </div>
                    <div class="installBox" style="position: static; display: flex" v-else>
                      <mu-button color="#017afe" style="border-radius: 10px" small textColor="#fff" @click="installApp(false)">
                        <img v-if="!downloaded" src="@/assets/download.png" style="width: 16px; margin-right: 8px" alt="" />
                        <span v-if="downloaded">{{ $t('index.back_desktop') }}</span>
                        <span v-else>
                          {{ downloadFlag ? $t('index.installing') : $t('index.install') }}
                        </span>
                      </mu-button>
                      <mu-button v-if="!androidFlag" style="background-color: #fff; border: 1px solid #017afe; border-radius: 10px; margin-left: 8px" small textColor="#017afe" @click="installTipShow = true">
                        <img src="@/assets/certify.png" style="width: 16px; margin-right: 8px" alt="" />
                        {{ $t('index.go_cert') }}
                      </mu-button>
                    </div>
                  </header>
                </div>
              </div>
            </section>
            <div class="comment l-content-width" style="width: 87.5%; border-bottom: none" v-if="!pcFlag">
              <div class="ratingItem">
                <span>1.88{{ $t('index.pingfen') }}</span>
                <span style="font-weight: 700; font-size: 20px; margin: 8px 0">4.9</span>
                <img src="@/assets/fiveStar.png" alt="" style="height: 18px" />
              </div>
              <div class="ratingItem">
                <span>{{ $t('index.age') }}</span>
                <span style="font-weight: 700; font-size: 20px; margin: 8px 0">18+</span>
                <span>{{ $t('index.age_unit') }}</span>
              </div>
              <div class="ratingItem">
                <span>{{ $t('index.rank') }}</span>
                <span style="font-weight: 700; font-size: 20px; margin: 8px 0">#1</span>
                <span>{{ $t('index.yy') }}</span>
              </div>
              <div class="ratingItem">
                <span>{{ $t('index.size') }}</span>
                <span style="font-weight: 700; font-size: 20px; margin: 8px 0">{{ appInfo.filesize }}</span>
                <span>{{ $t('index.M') }}</span>
              </div>
            </div>

            <div v-if="appInfo.preview" style="width: 87.5%; overflow-x: scroll; overflow-y: hidden; display: flex; flex-wrap: nowrap; margin: 20px auto; scrollbar-width: none">
              <img :src="item" style="width: 100%; margin-right: 8px; border-radius: 8px" alt="" v-for="(item, index) in appInfo.preview" :key="index" />
            </div>

            <section class="l-content-width section section--bordered" v-if="appInfo.fileinfo" style="margin-top: 20px">
              <h2 class="section__headline">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit">{{ $t('index.Applicationintroduction') }}</font>
                </font>
              </h2>
              <div class="we-screenshot-viewer">
                <p>{{ appInfo.fileinfo }}</p>
              </div>
            </section>

            <div class="comment l-content-width" v-if="!pcFlag" style="width: 87.5%; border-bottom: none">
              <strong class="publicTitle2" style="font-size: 25px">{{ $t('index.comment') }}</strong>
              <div class="left">
                <b>4.9</b>
                <p>{{ $t('index.points') }}</p>
              </div>
              <div class="right">
                <div style="display: flex; justify-content: flex-end">
                  <div class="star_row">
                    <img src="@/assets/fiveStar.png" style="height: 12px" alt="" />
                    <div class="lineBox"><var class="v1"></var></div>
                  </div>
                </div>
                <div class="star_row">
                  <div style="display: flex; justify-content: flex-end">
                    <div style="height: 14px; width: 60px; overflow: hidden">
                      <img src="@/assets/fiveStar.png" style="height: 12px" alt="" />
                    </div>
                  </div>
                  <div class="lineBox"><var class="v2"></var></div>
                </div>
                <div class="star_row">
                  <div style="display: flex; justify-content: flex-end">
                    <div style="height: 14px; width: 45px; overflow: hidden">
                      <img src="@/assets/fiveStar.png" style="height: 12px" alt="" />
                    </div>
                  </div>
                  <div class="lineBox"><var class="v3"></var></div>
                </div>
                <div class="star_row">
                  <div style="display: flex; justify-content: flex-end">
                    <div style="height: 14px; width: 30px; overflow: hidden">
                      <img src="@/assets/fiveStar.png" style="height: 12px" alt="" />
                    </div>
                  </div>
                  <div class="lineBox"><var class="v4"></var></div>
                </div>
                <div class="star_row">
                  <div style="display: flex; justify-content: flex-end">
                    <div style="height: 14px; width: 15px; overflow: hidden">
                      <img src="@/assets/fiveStar.png" style="height: 12px" alt="" />
                    </div>
                  </div>
                  <div class="lineBox"><var class="v5"></var></div>
                </div>
                <p>1.88w {{ $t('index.Score') }}</p>
              </div>
            </div>

            <div style="width: 87.5%; border-radius: 15px; background-color: #f2f1f5; padding: 15px; margin: 0 auto">
              <div style="width: 100%; display: flex; flex-direction: column">
                <div style="display: flex; width: 100%; justify-content: space-between; align-items: center">
                  <span style="font-size: 0.32rem; font-weight: 700">{{ $t('index.strongly', appNameParam) }}</span>
                  <div style="color: #999; font-size: 0.28rem; width: 80px; text-align: right">{{ date }}</div>
                </div>
                <div style="display: flex; width: 100%; justify-content: space-between; align-items: center; margin-top: 8px">
                  <img src="@/assets/finveStarOrange.png" style="height: 14px" alt="" />
                  <span style="color: #999; font-size: 0.3rem">James Levine</span>
                </div>
                <div style="width: 100%; color: #222; font-size: 0.3rem; margin-top: 10px">{{ $t('index.strongly_desc', appNameParam) }}</div>
              </div>
            </div>
            <!-- PC样式 -->
            <section class="l-content-width section section--bordered section--information" v-if="pcFlag">
              <div>
                <h2 class="section__headline">
                  <font style="vertical-align: inherit">
                    <font style="vertical-align: inherit">{{ $t('index.info') }}</font>
                  </font>
                </h2>
                <dl class="information-list information-list--app medium-columns l-row">
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.size') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ appInfo.filesize }}</font>
                      </font>
                    </dd>
                  </div>

                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.updateTime') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ appInfo.updatetime }}</font>
                      </font>
                    </dd>
                  </div>
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.yuyan') }}</font>
                      </font>
                    </dt>
                    <dd class="we-truncate we-truncate--multi-line we-truncate--interactive information-list__item__definition">
                      <div data-clamp="" class="we-clamp">
                        <p dir="ltr" data-test-bidi="">
                          <font style="vertical-align: inherit">
                            <font style="vertical-align: inherit">{{ $t('index.yytext') }}</font>
                          </font>
                        </p>
                      </div>
                    </dd>
                  </div>
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.age') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.agetext') }}</font>
                      </font>
                    </dd>
                  </div>

                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">Copyright</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition information-list__item__definition--copyright">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ appInfo.copyright }}</font>
                      </font>
                    </dd>
                  </div>
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.jiage') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.free') }}</font>
                      </font>
                    </dd>
                  </div>
                </dl>
              </div>
            </section>
            <section class="l-content-width section section--bordered section--information" v-if="pcAdShow && pcFlag && pcAd.status === 'show' && whiteList.indexOf(appInfo.user_id) === -1 && appInfo.point <= 0">
              <div style="margin: 0 auto; max-width: 80%; display: flex; justify-content: center; position: relative; cursor: pointer" @click="jumpUrl(pcAd.url)">
                <img :src="pcAd.image.includes('https://') ? pcAd.image : pcAd.url + pcAd.image" alt="" srcset="" style="max-width: 100%; height: auto" />
                <div class="topright" @click.stop="pcAdShow = false">x</div>
              </div>
            </section>
            <div style="width: 87.5%; margin: 20px auto; display: flex; flex-direction: column; border-top: 1px solid #eee" v-if="appInfo.logmassage">
              <span style="font-size: 24px; font-weight: 700; margin-top: 20px">{{ $t('index.Updatecontent') }}</span>
              <div style="width: 100%; margin-top: 8px; font-size: 16px; color: #222">{{ appInfo.logmassage }}</div>
            </div>

            <!-- 移动端样式 -->
            <div class="appInfo" v-if="!pcFlag" style="width: 87.5% !important; margin: 0 auto; border-top: 1px solid #eee; margin-top: 30px">
              <strong class="publicTitle">{{ $t('index.info') }}</strong>
              <div class="box">
                <ul>
                  <li>
                    <span>{{ $t('index.gongys') }}</span>
                    <p>{{ appInfo.ipa_name }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.size') }}</span>
                    <p>{{ appInfo.filesize }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.version_no') }}</span>
                    <p>{{ appInfo.xlversion }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.yuyan') }}</span>
                    <p>{{ $t('index.yytext') }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.age') }}</span>
                    <p>{{ $t('index.agetext') }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.jiage') }}</span>
                    <p>{{ $t('index.free') }}</p>
                  </li>
                  <li v-if="appInfo.support_url">
                    <span style="overflow: visible">{{ $t('index.support_url') }}</span>
                    <p>{{ appInfo.support_url }}</p>
                  </li>
                  <li v-if="appInfo.copyright">
                    <span>{{ $t('index.banquan') }}</span>
                    <p>{{ appInfo.copyright }}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div style="margin: 0 auto; max-width: 90%; display: flex; justify-content: center; position: relative; cursor: pointer" @click="jumpUrl(mobileAd.url)" v-if="mobileAdShow && !pcFlag && mobileAd.status === 'show' && whiteList.indexOf(appInfo.user_id) === -1 && appInfo.point <= 0">
              <img :src="mobileAd.image.includes('https://') ? mobileAd.image : mobileAd.url + mobileAd.image" alt="" srcset="" style="max-width: 100%; height: auto" />
              <div class="topright" @click.stop="mobileAdShow = false">x</div>
            </div>

            <div class="appInfo" v-if="appInfo && appInfo.isadvertisingisaon_status == 1">
              <a :href="appInfo && appInfo.isadvertisingisaon && appInfo.isadvertisingisaon.url">
                <img :src="appInfo && appInfo.isadvertisingisaon && appInfo.isadvertisingisaon.image" style="width: 100%" alt="" srcset="" />
              </a>
            </div>
          </div>
          <!-- pc端样式 -->
          <div style="position: relative; top: 40px; left: 50%; transform: translate(-50%, 0); display: flex; flex-direction: column; align-items: center" v-else>
            <span style="font-weight: 700; margin-bottom: 40px">{{ $t('index.scan_download') }}</span>

            <img :src="appInfo && appInfo.image" style="width: 97px; height: 97px; border-radius: 20px" />
            <span style="margin: 30px auto">{{ appInfo.ipa_name }}</span>
            <img :src="qrcode" alt="" style="width: 140px; height: 140px" />
            <div style="margin-top: 30px">{{ $t('index.statement') }}</div>
          </div>
        </main>
      </div>

      <mu-dialog title="" width="95%" :open.sync="reportFlag">
        <form onsubmit="return false;">
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.name') }}：</label>
            <input v-model="username" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.IDnumber') }}：</label>
            <input v-model="sf" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.content') }}：</label>
            <textarea v-model="title" rows="4"></textarea>
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.phonenumber') }}：</label>
            <input v-model="mobile" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.identifyingcode') }}：</label>
            <input v-model="cache" type="text" placeholder="" style="width: 80px" />
            <button class="ui-btn" @click="sendSms" style="margin-top: 6px; margin-left: 5px">
              {{ $t('index.Verification') }}
            </button>
          </div>
        </form>

        <mu-button slot="actions" color="primary" @click="closeReport">{{ $t('index.qx') }}</mu-button>
        <mu-button slot="actions" color="primary" @click="saveReport">{{ $t('index.tijiao') }}</mu-button>
      </mu-dialog>
    </div>
    <hongbao v-show="!ishome" @back="toggleBack" :appInfo="appInfo" :hongbaoAd="hongbaoAd" :whiteList="whiteList" :jumpObject="jumpObject" />
    <div class="modalClass" v-show="modalShow">
      <div style="margin-top: 25px">{{ $t('index.plaeseComputed') }}：</div>
      <div style="margin-top: 25px; width: 100%; text-align: center; font-size: 16px; color: #333">
        <span>{{ validateInfo.num1 }}</span>
        <span style="margin: 0 15px">+</span>
        <span>{{ validateInfo.num2 }}</span>
        <span style="margin-left: 15px">= ？</span>
      </div>
      <input v-model="result" type="number" :placeholder="$t('index.inputResult')" style="margin-top: 25px; height: 30px" />
      <div style="margin: 25px auto" class="confirm" @click="confirm">
        {{ $t('index.confirm') }}
      </div>
      <div class="close" @click="modalShow = false">×</div>
    </div>

    <mu-dialog :append-body="false" width="80%" :open.sync="installTipShow" style="border-radius: 15px">
      <div style="width: 100%; display: flex; flex-direction: column">
        <div style="width: 100%">
          <span>1.{{ $t('index.cert_before') }}</span>
          <span style="color: #cc0000">{{ $t('index.app_must') }}</span>
        </div>
        <div style="width: 100%">
          <span>2.{{ $t('index.you_need') }}</span>
          <span style="color: #cc0000">{{ $t('index.setting_step') }}</span>
          <span>{{ $t('index.company_app') }}</span>
        </div>
      </div>
      <mu-button slot="actions" flat small textColor="#2196f3" @click="(installTipShow = false), (tipsShow = true)">{{ $t('index.text_img') }}</mu-button>
      <mu-button slot="actions" flat small textColor="#2196f3" @click="goCert">{{ $t('index.confirm') }}</mu-button>
    </mu-dialog>
    <mu-dialog :append-body="false" width="80%" :title="$t('index.ifTips')" :open.sync="tipsShow" style="border-radius: 15px">
      <img src="@/assets/step1.jpg" style="width: 100%" alt="" />
      <div style="width: 100%; display: flex; justify-content: center">
        <mu-button color="#549186" textColor="#fff" round @click="(tipsShow = false), (stepShow = true)">{{ $t('index.click_resolve') }}</mu-button>
      </div>
    </mu-dialog>
    <mu-dialog :append-body="false" width="80%" :open.sync="ios17tipShow" style="border-radius: 15px; padding: 15px">
      <div style="font-size: 13px; color: #333">{{ $t('index.ios17tip') }}</div>
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <mu-button color="#549186" textColor="#fff" round @click="ios17tipShow = false">{{ $t('index.confirm') }}</mu-button>
      </div>
    </mu-dialog>
    <mu-dialog style="padding: 0" :append-body="false" :open.sync="stepShow" width="80%">
      <div style="width: 100%; display: flex; flex-direction: column">
        <mu-carousel hide-controls :cycle="false" style="height: 150px" hide-indicators @change="carouselChange">
          <mu-carousel-item style="height: 145px">
            <img src="@/assets/step2.jpg" style="width: 100%" alt="" />
          </mu-carousel-item>
          <mu-carousel-item style="height: 130px">
            <img src="@/assets/step3.jpg" style="width: 100%" alt="" />
          </mu-carousel-item>
          <mu-carousel-item style="height: 145px">
            <img src="@/assets/step4.jpg" style="width: 100%" alt="" />
          </mu-carousel-item>
        </mu-carousel>
        <div style="width: 100%; display: flex; flex-direction: column; color: #42a5f5; font-size: 12px; align-items: center; text-align: center; margin-top: 10px">
          <span>{{ $t('index.ccert_step') }}</span>
          <span style="margin-top: 8px" v-if="stepIndex == 0">{{ $t('index.step1') }}</span>
          <span style="margin-top: 8px" v-if="stepIndex == 1">{{ $t('index.step2') }}</span>
          <span style="margin-top: 8px" v-if="stepIndex == 2">{{ $t('index.step3') }}</span>
        </div>
        <div style="margin: 10px auto; display: flex">
          <div :class="{ dot: true, dotActive: stepIndex == 0 }"></div>
          <div :class="{ dot: true, dotActive: stepIndex == 1 }" style="margin: 0 15px"></div>
          <div :class="{ dot: true, dotActive: stepIndex == 2 }"></div>
        </div>
      </div>
    </mu-dialog>
  </div>
</template>

<script>
import { mixin } from '../../mixins/mixin.js';
import hongbao from './hongbao';
const today = new Date();
export default {
  mixins: [mixin],
  components: {
    hongbao,
  },
  name: 'Home',
  data() {
    return {
      ios17tipShow: false,
      downloaded: false,
      home: 2,
      stepIndex: 0,
      installTipShow: false,
      tipsShow: false,
      stepShow: false,
      androidFlag: false,
      date: `${today.getMonth() + 1}-${today.getDate()}`,
    };
  },
  created() {
    this.getBroswerType();
  },
  computed: {
    mime() {
      return this.appInfo.mimetype;
    },
    cannotInstall() {
      if (this.mime == 'Android') {
        //包是安卓包
        return !this.androidFlag;
      } else {
        /* 包是IOS */
        return this.androidFlag;
      }
    },
    appNameParam() {
      return { appName: this.appInfo.ipa_name };
    },
  },
  methods: {
    carouselChange(index) {
      this.stepIndex = index;
    },
    getBroswerType() {
      var u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.androidFlag = !isiOS;
    },
    goCert() {
      if (this.getIosVer() >= 17) {
        this.ios17tipShow = true;
        return;
      } else {
        window.open('https://hesus.gqkzzhuv.com/api/public/embedded.mobileprovision');
      }
    },
    getIosVer() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 匹配iOS设备的userAgent
      var match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (match) {
        // 返回版本号，格式为"主版本号.次版本号.修订号"（如果有的话）
        let version = `${match[1]}.${match[2]}${match[3] ? `.${match[3]}` : ''}`;
        return Number(version.split('.')[0]);
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .mu-dialog {
  border-radius: 12px !important;
}
.modalClass {
  background: #fff;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%);
  width: 200px;
  // height: 300px;
  border-radius: 18px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #666;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.confirm {
  // width: 68px;
  padding: 0 25px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #1890ff;
  font-size: 14px;
  border-radius: 16px;
}
.ratingItem {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  position: relative;
  &::after {
    content: '';
    display: block;
    height: 50px;
    border-right: 1px solid #eee;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0.32rem, -50%);
  }
}
.dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #78829b;
}
.dotActive {
  background-color: #354dc8;
}
.pcBg {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/download_bg.jpg');
  background-size: cover;
}
@import url(@/assets/theme.less);
</style>
