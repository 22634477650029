/*
 * @Description: 
 * @Version: 1.0
 * @Autor: Jinx
 * @Date: 2024-06-02 22:57:33
 * @LastEditors: Jinx
 * @LastEditTime: 2024-06-27 23:49:56
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:id',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/hongbao',
    name: 'hongbao',
    component: () => import('@/views/components/homeTemplate/hongbao.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
